body{
    font-family: "Poppins", sans-serif;
}
.progreso_bar {
    width: 100%;
}

#contenedor {
    width: 90%;
    height: 1.5em;
    margin: 0 auto;
    background-color: #B8D6BB;
    border-radius: 5rem;
}

#barra {
    width: 16%;
    height: 100%;
    background: #4B99FF;
    border-radius: 5rem;
}

#barra {
    animation: progreso 2s linear;
}

#texto:after {
    animation: porcentaje 2s linear;
}

@keyframes porcentaje {
    0% {
        content: '0%';
    }
    16% {
        content: '16%';
    }
    33% {
        content: '33%';
    }
    49% {
        content: '49%';
    }
    66% {
        content: '66%';
    }
    83% {
        content: '83';
    }
    100% {
        content: '100%';
    }
}

.bg-facebook {
    color: #129AF6;
    font-size: 20px;
}

.bg-degrado {
    background: rgb(1, 97, 62);
    background: linear-gradient(121deg, rgba(1, 97, 62, 1) 0%, rgba(4, 91, 83, 1) 45%, rgba(0, 122, 32, 1) 100%);
}

.neon {
    background: #01613ed3;
}

.slick-slider {
    margin: auto;
}

body .slick-prev,
body .slick-next {
    width: 100%;
    height: 100%;
    width: 40px;
    background: rgba(255, 255, 255, 0.2) !important;
    z-index: 1;
}

.slick-prev {
    left: 1px !important;
}

.slick-next {
    right: -1px !important;
}

.z-index-1 {
    z-index: 9;
    position: relative;
}

.z-index-2 {
    z-index: 99;
}

.z-index-3 {
    z-index: 999;
}

.z-index-4 {
    z-index: 9999;
    position: relative;
}


/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.contenedor::-webkit-scrollbar {
    -webkit-appearance: none;
}

.contenedor::-webkit-scrollbar:vertical {
    width: 10px;
}

.contenedor::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
    display: none;
}

.contenedor::-webkit-scrollbar:horizontal {
    height: 10px;
}

.contenedor::-webkit-scrollbar-thumb {
    background-color: #4B99FF;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.contenedor::-webkit-scrollbar-track {
    border-radius: 10px;
}

.contenedor-2::-webkit-scrollbar {
    -webkit-appearance: none;
}

.contenedor-2::-webkit-scrollbar:vertical {
    width: 10px;
}

nav{
    background-color: #000a25!important;
}

#navbar-user > ul{
    background-color: #000a25!important;
    color: white;
    margin: 0;
}
#navbar-user > ul > li > a{
    color: white!important;
}

nav>a{
    color: white!important;
}

.contenedor-2::-webkit-scrollbar-button:increment,
.contenedor::-webkit-scrollbar-button {
    display: none;
}

.contenedor-2::-webkit-scrollbar:horizontal {
    height: 10px;
}

.contenedor-2::-webkit-scrollbar-thumb {
    background-color: #4B99FF;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.contenedor-2::-webkit-scrollbar-track {
    border-radius: 10px;
}

.tab-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
}


/* :checked - resize to full height */

.tab input:checked~.tab-content {
    max-height: 100vh;
}


/* Label formatting when open */

.tab input:checked+label {
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem;
    /*.text-xl*/
    padding: 1.25rem;
    /*.p-5*/
    border-left-width: 2px;
    /*.border-l-2*/
    border-color: #4B99FF;
    /*.border-indigo*/
    background-color: #f8fafc;
    /*.bg-gray-100 */
    color: #4B99FF;
    /*.text-indigo*/
}


/* Icon */

.tab label::after {
    float: right;
    right: 0;
    top: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.2;
    font-size: 1.25rem;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
}


/* Icon formatting - closed */

.tab input[type=checkbox]+label::after {
    content: "+";
    font-weight: bold;
    /*.font-bold*/
    border-width: 1px;
    /*.border*/
    border-radius: 9999px;
    /*.rounded-full */
    border-color: #b8c2cc;
    /*.border-grey*/
}

.tab input[type=radio]+label::after {
    content: "\25BE";
    font-weight: bold;
    /*.font-bold*/
    border-width: 1px;
    /*.border*/
    border-radius: 9999px;
    /*.rounded-full */
    border-color: #b8c2cc;
    /*.border-grey*/
}


/* Icon formatting - open */

.tab input[type=checkbox]:checked+label::after {
    transform: rotate(315deg);
    background-color: #4B99FF;
    /*.bg-indigo*/
    color: #f8fafc;
    /*.text-grey-lightest*/
}

.tab input[type=radio]:checked+label::after {
    transform: rotateX(180deg);
    background-color: #4B99FF;
    color: #f8fafc;
}

.bg-aply-600 {
    background-color: #2784FE;
}

.text-aply-600 {
    color: #2784FE;
}

.svg-inject {
    fill: #569e26 !important;
}

.cuadros {
    -webkit-box-shadow: 0px 9px 12px -1px rgba(143, 143, 143, 0.5);
    -moz-box-shadow: 0px 9px 12px -1px rgba(143, 143, 143, 0.5);
    box-shadow: 0px 9px 12px -1px rgba(143, 143, 143, 0.5);
    border-radius: 0.75rem;
    background-color: #FFFFFF;
    color: #4A4A4A;
    cursor: pointer;
    /* efecto  */
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 700ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.cuadros:hover {
    -webkit-box-shadow: inset 0px 0px 0px -200px rgba(143, 143, 143, 0);
    -moz-box-shadow: inset 0px 0px 0px -200px rgba(143, 143, 143, 0);
    box-shadow: inset 0px 0px 0px -200px rgba(143, 143, 143, 0);
    --tw-bg-opacity: 2;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
    color: #2784FE;
}

.active {
    -webkit-box-shadow: inset 0px 0px 0px -200px rgba(143, 143, 143, 0);
    -moz-box-shadow: inset 0px 0px 0px -200px rgba(143, 143, 143, 0);
    box-shadow: inset 0px 0px 0px -200px rgba(143, 143, 143, 0);
    --tw-bg-opacity: 2;
    
    color: #2784FE;
}

.bg-degradado {
    background: rgb(41, 171, 226);
    background: linear-gradient(180deg, rgba(41, 171, 226, 1) 0%, rgba(9, 31, 122, 1) 100%);
}


/* botones  */

.input-1 {
    line-height: 1.5rem;
    font-weight: 700;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 1rem;
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.input-1:focus {
    outline: none;
}


/* focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent
hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 */

.input-2 {
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.input-2:focus {
    outline: none;
}


/* border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent */

.input-3 {
    --tw-text-opacity: 1;
    color: rgba(74, 74, 74, var(--tw-text-opacity));
    border: 1px solid #dddddd;
    font-size: .95rem;
    line-height: 1.5rem;
    font-weight: 500;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.75rem;
    margin-bottom: .5rem;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.input-3:hover {
    outline: none;
    border-color: transparent;
}

.input-3:focus {
    outline: none;
    border-color: transparent;
}

.btn-1 {
    border-radius: 0.75rem;
    font-weight: 600;
    /* font-size: 0.95rem; */
    border-color: transparent;
    border: 1px;
    line-height: 1.5rem;
    padding: 0.25rem 2.5rem;
    /* transition  */
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.btn-1.hover,
.btn-1:focus {
    outline: none;
    border: transparent;
}

.text-10xl {
    font-size: 9.5rem;
    line-height: 1;
}

.crop {
    color: rgb(234 179 8);
}

.w-100 {
    width: 100px !important;
}

.swal2-styled.swal2-deny {
    border-radius: 8em !important;
}

.swal2-styled.swal2-confirm {
    border-radius: 8em !important;
}

.swal2-styled.swal2-cancel {
    border-radius: 8em !important;
}

.swal2-styled {
    padding: 4px 1em !important;
    font-weight: 700 !important;
}

.swal2-image {
    margin: 30px auto 1px !important;
}

.swal2-title {
    padding: .4em 1em 0 !important;
}

.w-95 {
    width: 23rem;
}

html, body { height: 100%; }
body { margin: 0; font-family: "Poppins", sans-serif;}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    background-color: #ddd;
    color: #333;
    font-size: 12px;
    font-family: Raleway;
    position: absolute;
    padding: 3px 20px;
    bottom: -2.9em;
    left: -80%;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #ddd;
    opacity: 0;
    border: 1px solid #ddd;
    z-index: 99999;
    visibility: hidden;
    border-radius: 6px;
}

[data-title] {
    position: relative;
}


.mat-dialog-content {
    padding: 0;
    margin: 0;
  }

  ::ng-deep .mat-dialog-container {
    margin: 0 !important;
    padding: 0 !important;
  }

.btn-sm {
    border-radius: 0.75rem;
    font-weight: 500;
    border-color: transparent;
    border: 1px;
    line-height: 1.5rem;
    padding: 0.25rem 1.5rem;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.btn-sm.hover,
.btn-sm:focus {
    outline: none;
    border: transparent;
}

